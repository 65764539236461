<template>
    <marketing-layout>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <section-block class="text-center">
                        <div class="section-header text-muted">
                            {{ $t('global.pageNotFound.sectionTitle') }}
                        </div>
                        <h3 class="my-3">
                            {{ $t('global.pageNotFound.title') }}
                        </h3>
                        <h5 class="text-muted fw-light">
                            <i18n path="global.pageNotFound.message">
                                <template #homePageLink>
                                    <router-link :to="{ path: onContinue }">
                                        Home Page
                                    </router-link>
                                </template>
                            </i18n>
                        </h5>
                    </section-block>
                </div>
            </div>
        </div>
    </marketing-layout>
</template>

<script>
    import MarketingLayout from '@/layouts/Marketing'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import SectionBlock from '@/components/SectionBlock'

    export default {
        components: {
            'marketing-layout': MarketingLayout,
            'section-block': SectionBlock,
        },
        mounted() {
            this.$logEvent('view_page_not_found')
        },
        computed: {
            onContinue() {
                appSessionStorage.setItem(localStorageKey.clearStorageOnNavigation, 'true')
                return '/'
            },
        },
    }
</script>
